import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const blockBg = img =>
  `url(${img}) center / cover no-repeat fixed padding-box content-box black`;

const GridBlock = ({
  blockCSS,
  colorCSS,
  image,
  link,
  title,
  transitionImage,
}) => {
  const imageCSS = 'object-cover w-full h-full absolute';
  const contentCSS = 'h-full w-full absolute flex items-center justify-center';
  const titleCSS =
    'transition font-bold font-header border-b text-white text-center md:mt-6 group-hover:mt-0 md:text-xl mobile-fix';
  return (
    <AniLink
      paintDrip
      hex="#A9C651"
      to={link}
      duration={1.5}
    >
    </AniLink>
  );
};
GridBlock.propTypes = {
  blockCSS: PropTypes.string.isRequired,
  colorCSS: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  transitionImage: PropTypes.any.isRequired,
};

export default GridBlock;
